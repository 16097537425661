import React from "react"
import Hero from "../../components/hero-ms"
import SFDetails from "../../components/ms-details"

import "../../assets/index.css"
import Layout from "../../layouts/layout"


export default () => (
    <Layout title="Managed Services | Ladd Partners"> 
        <Hero/>
        <SFDetails/>
    </Layout>
)
